



































































import { Validator } from '@/constant/Mixins';
import { Toaster } from '@/constant/Toaster';
import { ServerResponse } from '@/services/response.types';
import { UserModel } from '@/store/auth/types';
import { AdditionalCoverModel, ProductMotorState } from '@/store/product/motor/types';
import { ProductModel, ProductSummaryModel } from '@/store/product/types';
import Component from 'vue-class-component';
import { Mixins } from 'vue-property-decorator';
import { State } from 'vuex-class';

interface AdditionalInterface {
  label: string;
  value: string;
  checked: boolean;
  range_input: boolean;
  range_value: number | string;
  type: number;
}

interface GeneralSummaryInterface {
  label: string;
  value: any;
  is_currency?: boolean;
}

@Component({
  name: 'CompareLayout',
  components: {},
  mixins: [],
})
export default class CompareLayout extends Mixins(Validator) {

  @State('user', { namespace: 'auth' }) user!: UserModel;

  public formVoucherCode = null;
  public hasAdditional = false;
  public formAdditional: AdditionalInterface[] = [];
  public formAdditionalPassanger: any = 4;
  public type: any = null;
  public product: string | null = null;
  public generalSummary: GeneralSummaryInterface[] = [
    {
      label: 'User Info',
      value: '',
    },
    {
      label: 'Cakupan',
      value: '',
    },
    {
      label: 'Tahun',
      value: '',
    },
    {
      label: 'Area',
      value: '',
    },
    {
      label: 'Pertanggungan',
      value: '',
      is_currency: true,
    },
    {
      label: 'Premi Dasar',
      value: '',
      is_currency: true,
    },
    {
      label: 'Loading Rate',
      value: '',
      is_currency: true,
    },
    {
      label: 'Premi Tambahan',
      value: '',
      is_currency: true,
    },
    {
      label: 'Administrasi',
      value: '',
      is_currency: true,
    },
    {
      label: 'Diskon',
      value: '',
      is_currency: true,
    },
    {
      label: 'Subtotal',
      value: '',
      is_currency: true,
    },
  ];

  public mounted(): void {
    this.Init();
  }

  /**
   * Init
   */
  public Init() {
    /**
     * array index:
     * - 0 => type
     * - 1 => product
     * - 2 => actions
    */
    let match = /compare\/([a-z-]+)\/([a-z]+).+/g.exec(location.pathname) || [];
    this.type = match[0];
    if (typeof match[1] === 'undefined') {
      Toaster.Warning("silahkan pilih ulang produk", { title: "Produk tidak di temukan!" });
      // this.$router.push("/")
    }
    this.product = (match[1] as string).replace("-", "");

    this.formAdditionalPassanger = localStorage.getItem(`${this.product}-passanger-history`) ?? 1;
    window.onbeforeunload = () => {
      localStorage.setItem(`${this.product}-additional-history`, JSON.stringify(this.formAdditional));
      localStorage.setItem(`${this.product}-passanger-history`, this.formAdditionalPassanger.toString());
    };

    if (match.length >= 3) {
      let summary = new ProductSummaryModel
      this.$store.dispatch(`product/${this.product}/GetSummary`, this.$route.query.q).then((res: ServerResponse) => {
        // if (!this.user.full_name) {
        //   localStorage.setItem('redirect', JSON.stringify({ name: this.$route.name, query: this.$route.query }));
        //   Toaster.Warning('Login diperlukan!');
        //   this.$router.push({ name: 'login' });
        // }
        summary = res.data
        this.UpdateGeneralSummary(summary);
      });

      // escape additionals
      let availableAdditionalProduct = [
        'motor',
        'mobil'
      ];
      this.hasAdditional = availableAdditionalProduct.includes(this.product);
      if (this.hasAdditional) {
        let additional: Promise<AdditionalCoverModel[]> = this.$store.dispatch(`product/${this.product}/GetAdditional`);
        additional.then((adtc) => {
          let additionalHistory = localStorage.getItem(`${this.product}-additional-history`);
          if (additionalHistory) this.formAdditional = JSON.parse(localStorage.getItem(`${this.product}-additional-history`));

          this.formAdditional = adtc.map(this.formAdditionalSerializer);
          this.UpdateGeneralSummary(summary);
        });
      }
    }
  }

  /**
   * formAdditionalSerializer
   */
  public formAdditionalSerializer(item) {
    return {
      label: `${item.additional_category_name_ind} (${item.additional_category_alias})`,
      value: item.vehicle_additional_id,
      type: Number(item.type),
      checked: false,
      range_input: [1, 3, 4].indexOf(Number(item.type)) >= 0,
      range_value: 10000000
    };
  }

  public handlerAttempt = 0;
  /**
   * UpdateAdditionalsCover
  */
  public UpdateAdditionalsCover(ev: Event) {
    let formEL = ev.target as HTMLFormElement;
    if (formEL.querySelectorAll('.is-invalid').length === 0) {

      let productState = this.$store.state.product[this.product] as ProductMotorState;
      let params = Object.assign({}, productState.form);

      let storageKey = '';
      switch (this.product) {
        case 'mobil': { storageKey = 'car'; break; }
        default: { storageKey = this.product; break; }
      }
      params = JSON.parse(localStorage.getItem(`${storageKey}-history`));

      if (Object.keys(params).length === 0) { params = Object.assign({}, (productState as any).formCompare); }

      let valid = true;
      if (String(params?.suminsured).length === 0) valid = false;
      if (String(params?.coverage).length === 0) valid = false;
      if (String(params?.year).length === 0) valid = false;
      if (String(params?.plate).length === 0) valid = false;

      if (String(params.suminsured).indexOf(',') >= 0) params.suminsured = (params.suminsured as any).replaceAll(',', '');

      if (valid) {
        params.sort = 'z-a';

        this.formAdditional.forEach((additional, index) => {
          switch (additional.type) {
            case 1: {
              params.limit_tpl = Number(additional.range_value);
              break;
            }
            case 3: {
              params.limit_pad = Number(additional.range_value);
              break;
            }
            case 4: {
              params.limit_pap = Number(additional.range_value);
              params.limit_person = this.formAdditionalPassanger;
              break;
            }
          }
        });

        params.discount_code = this.formVoucherCode;

        let form = new FormData();
        Object.keys(params).forEach((key) => form.append(key, params[key]));
        form.append('product_id', productState.summary.product.product_id);
        form.append('limit_person', String(this.formAdditionalPassanger));
        this.formAdditional.filter((v) => v.checked).forEach((value, i) => form.append(`additional[]`, value.value));

        this.$store.dispatch(`product/${this.product}/CompareAction`, form).then((res: ServerResponse) => {
          let summary = this.$store.state.product[this.product].summary as ProductSummaryModel;
          let product = new ProductModel;

          if (res?.data?.listdata) product.SetRawAttributes(res?.data?.listdata[0]);
          else if (res?.data?.data?.listdata) product.SetRawAttributes(res?.data?.data?.listdata[0]);

          if (Number(product.product_id) > 0) {
            summary.product = product;
            this.UpdateGeneralSummary(summary);
          }
        }).catch(this.Init);
      } else {
        Toaster.Warning('Form yang di inputkan (plate) tidak valid!');
      }

    } else Toaster.Warning('Form yang di inputkan tidak valid!');
  }

  /**
   * UpdateGeneralSummary
   */
  public UpdateGeneralSummary(summary: ProductSummaryModel) {
    this.$store.commit(`product/${this.product}/set-summary`, summary);

    switch (this.product) {
      case 'kecelakaandiri': {
        this.generalSummary = [
          {
            label: 'Produk',
            value: summary.product.product_name,
          },
          {
            label: 'Periode',
            value: '1 Tahun',
          },
          {
            label: 'Pertanggungan',
            value: summary.product.col1,
            is_currency: true,
          },
          {
            label: 'Premi Dasar',
            value: summary.product.price,
            is_currency: true,
          },
          {
            label: 'Administrasi',
            value: Number(summary.product.handling_fee) + Number(summary.product.admin_fee),
            is_currency: true,
          },
          {
            label: 'Total',
            value: summary.product.total_price,
            is_currency: true,
          },
        ];
        break;
      }

      case 'kesehatan': {
        this.generalSummary = [];

        this.generalSummary.push({
          label: 'Perusahaan',
          value: summary.product.provider_name,
        });

        this.generalSummary.push({
          label: 'Produk',
          value: summary.product.health_package_name,
        });

        this.generalSummary.push({
          label: 'Disediakan Oleh',
          value: summary.product.LblPartnership,
        });

        this.generalSummary.push({
          label: 'Premi Dasar',
          value: summary.product.premi,
          is_currency: true,
        });

        if (summary.product.participant_status === "2" && summary.product.with_child === "1") {
          this.generalSummary.push({
            label: 'Administrasi',
            value: summary.product.premi_add_child,
            is_currency: true,
          });
        }

        this.generalSummary.push({
          label: 'Administrasi',
          value: Number(summary.product.handling_fee) + Number(summary.product.admin_fee),
          is_currency: true,
        });

        this.generalSummary.push({
          label: 'Biaya Penanganan',
          value: summary.product.handling_fee,
          is_currency: true,
        });

        this.generalSummary.push({
          label: 'Subtotal',
          value: Number(summary.product.total_premi) + Number(summary.product.handling_fee) + Number(summary.product.surcharge),
          is_currency: true,
        });

        this.generalSummary.push({
          label: 'Total',
          value: Number(summary.product.total_premi) + Number(summary.product.handling_fee) + Number(summary.product.surcharge),
          is_currency: true,
        });
        break;
      }

      case 'properti': {
        this.generalSummary = [
          {
            label: 'Produk',
            value: summary.product.product_name,
          },
          {
            label: 'Periode',
            value: '1 Tahun',
          },
          {
            label: 'Pertanggungan',
            value: summary.product.sum_insured,
            is_currency: true,
          },
          {
            label: 'Premi Dasar',
            value: summary.product.basic_premi,
            is_currency: true,
          },
          {
            label: 'Administrasi',
            value: summary.product.admin_fee,
            is_currency: true,
          },
          {
            label: 'Total',
            value: parseFloat(summary.product.basic_premi) + parseFloat(summary.product.admin_fee),
            is_currency: true,
          },
        ];
        break;
      }

      default: {
        // index
        // 0  => "User Info"
        this.generalSummary[0].value = this.user.full_name;
        // 1  => "Cakupan"
        this.generalSummary[1].value = summary.product.coverage_type;
        // 2  => "Tahun"
        this.generalSummary[2].value = summary.product.year;
        // 3  => "Area"
        this.generalSummary[3].value = summary.product.area;
        // 4  => "Pertanggungan"
        this.generalSummary[4].value = summary.product.sum_insured;
        // 5  => "Premi Dasar"
        this.generalSummary[5].value = summary.product.gross_premium;
        // 6  => "Loading Rate"
        this.generalSummary[6].value = summary.product.loading_rate_amount;
        // 7  => "Premi Tambahan"
        this.generalSummary[7].value = summary.product.additional_premi;
        // 8  => "Administrasi"
        this.generalSummary[8].value = Number(summary.product.admin_fee) + Number(summary.product.handling_fee);
        // 9  => "Diskon"
        // this.generalSummary[9].value = summary.product.discount_premium;
        if (['motor', 'mobil'].indexOf(this.product) >= 0) {
          // this.generalSummary.splice(9, 1);

          if (this.product === 'mobil') {
            this.formAdditional = this.formAdditional.filter((item) => summary.additionals.filter(item1 => item.label.indexOf(item1.additional_category_alias) >= 0).length>0);
            this.hasAdditional = true;
          }
        }

        // 10 => "Subtotal"
        this.generalSummary[10].value = Number(summary.product.discount_premium) + (Number(summary.product.admin_fee) + Number(summary.product.handling_fee)) + Number(summary.product.additional_premi) + Number(summary.product.loading_rate_amount);
        break;
      }
    }
  }

  /**
   * OnSubmitVoucher
   */
  public OnSubmitVoucher(ev: Event) {
    this.$store.dispatch(`product/${this.product}/UseVoucher`, { quotation_id: this.$route.query.q, form: new FormData(ev.target as HTMLFormElement) }).then((res) => {
      if (res.status.is_valid) this.UpdateGeneralSummary(res.data);
    });
  }
}
